<template>
  <div class="home">
    <van-tabs v-model="activeName" @change="handtabChange">
      <van-tab name="a" title="最新汇率">
        <div v-if="loadingNew" class="main-body load">
          <van-loading size="24px">加载中...</van-loading>
        </div>
        <latestexchangeChild
          v-else :quarter="quarter" :signed_week="signed_week" :week="week"/>
      </van-tab>
      <van-tab name="b" title="历史汇率">
        <historicalexchangeChild ref="childComponent"
         :changeDate="changeDate"
         :changeType="changeType"
         :exchangeRate="exchangeRate"
         :fetchDataNew="fetchDataNew"
         :clearAll="clearFilter"
         :pageTotal="pageTotal"
         :currentPage="currentPage"
         :filter_info="filter_info"
         :loading="loading"
         :loadingHistoryNew="loadingHistoryNew"
         :finished="finished"
         :tabStatus="tabStatus"
         :onsearch="onSearch"
         :emtry="emtry"
                                 :searchLoad="searchLoad"
        />
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
// @ is an alias to /src
import latestexchangeChild from "@/unit/latestexchangeChild.vue";
import historicalexchangeChild from "@/unit/historicalexchangeChild.vue";
import {GetExchangeList, GetHistoryeList} from "@/api";
import {Toast} from "vant";
import {isInterceptorCode} from "@/common/commonFuncs";
import moment from "moment";

export default {
  name: 'HomeView',
  components: {
    latestexchangeChild, historicalexchangeChild
  },
  data() {
      return {
         searchData:{},
         loadingNew:false,
         loadingHistoryNew:false,
         searchLoad:false,
          signed_week:'',
          week:'',
          quarter:'',
          exchangeRate:[],
          currentPage:1,
          limit:10,
          activeName: 2,
          loading:false,
          showToast:false,
          tabStatus:'',
          pageTotal:3,
          filter_info:{
             startVal:'',
             endVal:'',
             typeVal:'',
             typeId:''
          },
          emtry:'暂无数据，请先选择数据类型',
          finished:false,
          total:'',
          newList:[],
      }
  },
  methods: {
    handtabChange(val) {
      this.tabStatus = val
      this.finished=false
      // 切换成历史记录刷新列表
      if (val === 'b') {
        this.clearFilter()
        this.currentPage = 1
        this.getNewExchangeData()
      } else {
        this.getNewGoodslist()
      }
    },
    // 到底部开始下一页获取历史汇率
    fetchDataNew() {
      this.currentPage++;
      this.loadingHistoryNew = true; // 开始加载数据
      let para = {
        pageIndex: this.currentPage,
        pageSize: this.limit,
        sjlx:this.filter_info.typeId,
        sxrq:this.filter_info.startVal,
        dqrq:this.filter_info.endVal,
      }
      GetHistoryeList(para).then(data => {
        if (data.code === 200) {
          this.newList=data.data.data
          this.exchangeRate = this.exchangeRate.concat(data.data.data);
          this.loadingHistoryNew = false
          if (this.exchangeRate.length >= this.total) {
            this.finished = true;
          }
        } else {
          if (!isInterceptorCode(data.code)) {
            this.loadingHistoryNew = false
            Toast(data.msg)
          }
        }
      }).catch(() => {
        this.loadingHistoryNew = false
        Toast('服务器连接失败!')
      })
    },
    clearFilter(){
      this.filter_info.endVal=''
      this.filter_info.startVal=''
      this.filter_info.typeVal=''
      this.filter_info.typeId=''
    },
    changeDate(date){
      this.filter_info.startVal=date.start
      this.filter_info.endVal=date.end
    },
    changeType(type){
      this.filter_info.typeVal=type.value
      this.filter_info.typeId=type.key

    },
    onSearch(){
      this.currentPage=1
      this.finished=false
      this.searchLoad=true
      this.getNewExchangeData('search')

    },
    getNewExchangeData(e) {
      let para = {
        pageIndex: this.currentPage,
        pageSize: this.limit,
        sjlx:this.filter_info.typeId,
        sxrq:this.filter_info.startVal,
        dqrq:this.filter_info.endVal,
      }
      this.loading=true

      GetHistoryeList(para).then(data => {
        if (data.code === 200) {
        this.emtry='暂无数据'
        this.loading=false
        if(e==='search'){
          this.searchLoad=false
        }
          this.exchangeRate=data.data.data
          this.total=data.data.total
          this.newList=data.data.data
        } else {
          if (!isInterceptorCode(data.code)) {
            this.loading=false
            if(e==='search'){
              this.searchLoad=false
            }
              Toast(data.msg)
          }
        }
      }).catch(() => {
        this.loading=false
        if(e==='search'){
          this.searchLoad=false
        }
          Toast('服务器连接失败!')
      })
    },
    // 获取最新汇率
    getNewGoodslist() {
      this.loadingNew = true
      GetExchangeList().then(data => {
        if (data.code === 200) {
          this.loadingNew = false
          this.signed_week = data.data[0]
          this.week = data.data[1]
          this.quarter = data.data[2]
        } else {
          if (!isInterceptorCode(data.code)) {
            this.loadingNew = false
            Toast(data.msg)
          }
        }
      }).catch(() => {
        this.loadingNew = false
        Toast('服务器连接失败!')
      })
    },
  },
  created() {
    this.getNewGoodslist()
  }
}
</script>
<style scoped>
.home {
    box-sizing: border-box;
    background: #F5F5FD;
    width: 100%;
    padding: 30px;
}

.home >>> .van-tabs--line .van-tabs__wrap {
    height: 103px;
    border-radius: 20px;
}

.home >>> .van-tabs__line {
    width: 120px;
}

.home >>> .van-tab__text--ellipsis {
    font-size: 30px;
}

.home >>> .van-tab {
    margin-left: 30px;
    flex: none;
    font-weight: normal;
    color: #999999;
    justify-content: inherit;
    font-size: 30px;
}

.home >>> .van-tab.van-tab--active {
    color: #419EFF;
    font-weight: bold;
}

.home >>> .van-tab:nth-child(2) {
    margin-left: 46px;
}

.home >>> .van-tabs__line {
    background: #419EFF;
}

.load {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
